// @ts-nocheck
/* eslint-disable */

'use client';

import {ReactElement, useEffect} from 'react';
import {usePageLayout} from '@modules/Core/hooks/state/pageLayout';

/**
 * Only job is to clean up stuff on page change as tempalate.tsx in Next.js rerenders on every page change
 * I .. LOVE .. next.js <3
 * @param children
 * @constructor
 */
export default function RootTemplate({children}: {children: ReactElement}) {
  const {clearTitle, clearActions, clearSubTitle, clearOptions, hideSoftwareUpdate} = usePageLayout();

  useEffect(
    () => () => {
      clearTitle();
      clearActions();
      clearSubTitle();
      clearOptions();
      hideSoftwareUpdate();
    },
    []
  );

  return children;
}
